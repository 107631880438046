import React, { useState } from "react";
import "./AppContent.scss";
import { useTelegram } from "../../hooks/useTelegram";
import { useEffect } from "react";
import { getData } from "store/actions/profile";
import { useDispatch, useSelector } from "react-redux";
import { getTgUser } from "store/slices/profileSlice";
import AppRouter from "router/AppRouter";
import Header from "components/Header/Header";
import MobileNav from "components/MobileNav/MobileNav";
import AdsBlockedDesk from "components/AdsBlockedDesk/AdsBlockedDesk";

const AppContent = () => {
  const tg = useTelegram();
  const dispatch = useDispatch();
  const tgId = useSelector((state) => state.profile.tgData.tgId);
  const [isAdsblocked, setIsAdsBlocked] = useState(false);

  const checkIsBlocked = () => {
    // check if adsBlocked
    
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src =
      "//www.highperformanceformat.com/b8cf70b3ea2d5849d9d0a40c0c9341c7/invoke.js";
    script.onerror = (err) => {
      
      setIsAdsBlocked(true);
    };
    script.onload = () => {
      setIsAdsBlocked(false);
      document.querySelector("#script-wrapper").innerHTML= ""
    };

    document.querySelector("#script-wrapper").appendChild(script);
  };

  useEffect(() => {
    if (tg) {
      tg.ready();

      if (tg?.initDataUnsafe?.user) {
        const { id, photo_url } = tg.initDataUnsafe?.user;

        dispatch(
          getTgUser({
            tgId: id,
            avatar: photo_url,
          })
        );
      }
    }

    checkIsBlocked();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tgId) {
      (async () => {
        await dispatch(getData());
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tgId]);

  return (
    <main className="appContent">
      <div style={{display: "none"}} id="script-wrapper"></div>
      {isAdsblocked ? (
        <AdsBlockedDesk />
      ) : (
        <>
          <Header />
          <AppRouter />
          <MobileNav />
        </>
      )}
    </main>
  );
};

export default AppContent;


