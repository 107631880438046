export const useAdController = (blockId) => {
  const AdController = window.Adsgram.init({ blockId });

  const onShowAd = () => {

    AdController.show()
      .then((result) => {
      })
      .catch((result) => {
        console.error("err", result);
      });
  };

  return onShowAd
};
